/* eslint-disable no-undef */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import clsx from 'clsx';
import api from '../../utils/api';
import AppContext from '../../contexts/AppContext';
import {
  HELP_CENTRE_URL_SG,
  HELP_CENTRE_URL_AU,
  HELP_CENTRE_URL_MY,
  HELP_CENTRE_URL_TH,
  MERCHANT_FAQ_URL,
  STAFF_APP_URL,
  USER_STATES,
  APP_SG_URL,
  APP_AU_URL,
  LEAD_INBOUND_URL,
} from '../../utils/constants';
import styles from './Navigation.module.scss';
import logoWhite from '../../assets/images/sb-for-business-logo.svg';

class NavigationRaw extends Component {
  state = {
    mobileMenuIsOpen: false,
  };

  /**
   * Static data
   * @todo helper function to link between routesConfig array's value's name and navmenuItems array's value's
   * reason - to avoid change the routes at two places.
   */
  navMenuItems = [
    {
      name: this.props.t('menu.overview'),
      slug: '/overview',
      isEnabled: (user) => Boolean(user),
    },
    {
      name: this.props.t('menu.segmentation'),
      slug: '/customer-insights',
      isEnabled: (user) => Boolean(user),
    },
    {
      name: this.props.t('menu.crm.index'),
      slug: '/broadcast',
      isEnabled: (user) => Boolean(user?.permissions?.crmEnabled),
      children: [
        {
          name: this.props.t('menu.crm.dropdown.notificationList'),
          slug: '/broadcast',
        },
        {
          name: this.props.t('menu.crm.dropdown.createNotification'),
          slug: '/broadcast/create-a-notification',
        },
      ],
    },
    {
      name: this.props.t('menu.finance.title'),
      slug: '/finance/invoice',
      isEnabled: (user) => Boolean(user),
      children: [
        {
          name: this.props.t('menu.finance.dropdown.invoices'),
          slug: '/finance/invoice',
        },
        {
          name: this.props.t('menu.payLaterOrders'),
          slug: '/finance/paylater',
          isEnabled: (user) => Boolean(user) && process.env.REACT_APP_REGION !== 'AU',
        },
      ],
    },
    {
      name: this.props.t('menu.finance.dropdown.transactions'),
      slug: '/transactions',
      isEnabled: (user) => Boolean(user?.permissions?.accessAdmin) || Boolean(user?.permissions?.merchantPortalEnabled),
    },
  ];

  /**
   * Event handlers
   */
  handleSignOut = async (setUser, resetFilterValues) => {
    await api.logout();
    setUser(USER_STATES.NOT_AUTHENTICATED);
    resetFilterValues();
    this.setState({ mobileMenuIsOpen: false });
    /**
     * in case error thrown and user tried to log out. should refresh the page.
     */
    window.location.href = '/signin';
  };

  handleHamburgerClick = () => {
    this.setState((currentState) => ({
      mobileMenuIsOpen: !currentState.mobileMenuIsOpen,
    }));
  };

  handleLinkClick = (slug = null) => {
    if (slug === '/broadcast') {
      ReactGA.event({
        category: 'Navigation Bar',
        action: 'Broadcast',
        label: 'Notification list / Create notification',
      });
    }
    this.setState({ mobileMenuIsOpen: false });
  };

  /**
   * Utilities
   */
  getMobileMenuClasses = () =>
    this.state.mobileMenuIsOpen
      ? `${styles['menu--mobile']} ${styles['menu--mobile--active']}`
      : `${styles['menu--mobile']}`;

  getMenuItemClass = (slug, user, isUserDropdown = false) => {
    if (isUserDropdown) {
      return this.props.location.pathname === slug
        ? `${styles.menu__item} ${styles['menu__item--active']}`
        : styles.menu__item;
    }
    if (user) {
      return styles['profile__menu-item'];
    }
    if (slug !== '/register') {
      return styles.menu__item;
    }
    return styles['menu__item--register'];
  };

  getHelpCentreUrl = (region) => {
    switch (region) {
      case 'SG':
        return HELP_CENTRE_URL_SG;
      case 'MY':
        return HELP_CENTRE_URL_MY;
      case 'TH':
        return HELP_CENTRE_URL_TH;
      case 'AU':
        return HELP_CENTRE_URL_AU;
      default:
        return '';
    }
  };

  getMenuItemContentClass = (slug, isUserDropdown = false) => {
    if (isUserDropdown) {
      return '';
    }

    if (slug !== '/register') {
      return styles['menu__item--content'];
    }

    return styles['menu__item--register-content'];
  };

  getUserProfileIconClass = (user) => {
    if (!user) {
      return styles.profile__none;
    }
    return styles.profile__image;
  };

  getUtilityMenuClass = (user) => {
    if (user) {
      return styles.profile__menu;
    }
    return styles.menu;
  };

  getRegionSpecificUtilityMenuItems = (isUserDropdown, user) => {
    if (process.env.REACT_APP_REGION !== 'SG') {
      return <></>;
    }
    return (
      <>
        <li className={this.getMenuItemClass('', user, isUserDropdown)}>
          <a
            href={STAFF_APP_URL}
            target="_blank"
            rel="noreferrer noopener"
            className={this.getMenuItemContentClass('', isUserDropdown)}
          >
            {this.props.t('menu.staffApp')}
          </a>
        </li>
        <li className={this.getMenuItemClass('', user, isUserDropdown)}>
          <a
            href={MERCHANT_FAQ_URL}
            target="_blank"
            rel="noreferrer noopener"
            className={this.getMenuItemContentClass('', isUserDropdown)}
          >
            {this.props.t('menu.helpCenter')}
          </a>
        </li>
      </>
    );
  };

  /**
   * Render methods
   */
  renderMenuItems = (user) => {
    return this.navMenuItems
      .filter(({ isEnabled }) => isEnabled(user))
      .map(({ name, slug, children, isNew }) => {
        return (
          <div
            key={slug}
            data-testid={`${name.replace(/\s/g, '')}NavLink`}
            className={clsx(`${styles.menu__item}`, {
              [`${styles['menu__dropdown-item']}`]: Boolean(children),
              [`${styles['menu__item--active']}`]: this.props.location.pathname === slug,
            })}
          >
            <li className="menu-li">
              <Link to={slug} onClick={() => this.handleLinkClick(slug)} className={styles['menu__item--link']}>
                {name}
                {isNew && <div className={styles['menu__item--newtag']}>{this.props.t('menu.newTag')}</div>}
              </Link>
            </li>
            {children && (
              <ul className={`${styles['menu__dropdown-item--content']}`}>
                {children
                  .filter(({ isEnabled }) => (isEnabled ? isEnabled(user) : true))
                  .map((child) => {
                    return (
                      <li key={child.slug} className={`${styles['menu__dropdown-item--content-item']}`}>
                        <Link
                          to={child.slug}
                          onClick={() => this.handleLinkClick(slug)}
                          className={styles['menu__item--link']}
                        >
                          {child.name}
                          {child.isNew && (
                            <div className={styles['menu__item--newtag']}>{this.props.t('menu.newTag')}</div>
                          )}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        );
      });
  };

  renderUtilityMenuItems = (user, setUser, resetFilterValues, isUserDropdown = false) => {
    if (user) {
      return (
        <>
          <li className={`menu-li ${this.getMenuItemClass('', user, isUserDropdown)}`}>
            <Link to="/outlet-codes" onClick={() => this.handleLinkClick('/outlet-codes')}>
              {this.props.t('menu.outletCodes')}
            </Link>
          </li>
          <li className={`menu-li ${this.getMenuItemClass('', user, isUserDropdown)}`}>
            <a href={this.props.t('termsModal.link')} rel="noopener noreferrer" target="_blank">
              {this.props.t('termsModal.linkText')}
            </a>
          </li>
          <li className={`menu-li ${this.getMenuItemClass('', user, isUserDropdown)}`}>
            <a href={this.getHelpCentreUrl(process.env.REACT_APP_REGION)} rel="noopener noreferrer" target="_blank">
              {this.props.t('menu.helpCentre')}
            </a>
          </li>
          <li className={`menu-li ${this.getMenuItemClass('', user, isUserDropdown)}`}>
            <button id="logoutBtn" onClick={() => this.handleSignOut(setUser, resetFilterValues)} type="button">
              {this.props.t('menu.logout')}
            </button>
          </li>
        </>
      );
    }

    return (
      <>
        {this.getRegionSpecificUtilityMenuItems(isUserDropdown)}
        <li className={`menu-li ${this.getMenuItemClass('/signin', user, isUserDropdown)}`}>
          <Link
            to="/signin"
            onClick={() => this.handleLinkClick('/signin')}
            className={this.getMenuItemContentClass('/signin', isUserDropdown)}
          >
            {this.props.t('menu.login')}
          </Link>
        </li>
        <li
          className={`menu-li ${clsx({
            [`${styles.menu__item}`]: isUserDropdown,
            [`${styles['menu__item--active']}`]: isUserDropdown && this.props.location.pathname === '/register',
            [`${styles['menu__item--register']}`]: !isUserDropdown,
          })}`}
        >
          {[APP_SG_URL, APP_AU_URL].includes(window.location.host) ? (
            <a
              href={`${LEAD_INBOUND_URL}/${window.location.host === APP_SG_URL ? 'sg' : 'au'}`}
              onClick={() => this.handleLinkClick('/register')}
              className={clsx({
                [`${styles['menu__item--register-content']}`]: !isUserDropdown,
              })}
            >
              {this.props.t('menu.getStarted')}
            </a>
          ) : (
            <Link
              to="/register"
              onClick={() => this.handleLinkClick('/register')}
              className={clsx({
                [`${styles['menu__item--register-content']}`]: !isUserDropdown,
              })}
            >
              {this.props.t('menu.getStarted')}
            </Link>
          )}
        </li>
      </>
    );
  };

  renderUserName = (user) => {
    const capitalize = (str) => {
      if (typeof str !== 'string') return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (user) {
      const firstName = capitalize(user.firstName);
      const lastName = capitalize(user.lastName);

      return `${firstName} ${lastName}`;
    }

    return '';
  };

  renderNavigation = ({ setUser, user: userState, resetFilterValues }) => {
    const user = userState;

    return (
      <nav>
        <div className={this.getMobileMenuClasses()}>
          <div className="container">
            <ul id="mobileMenu">
              {this.renderMenuItems(user)}
              {this.renderUtilityMenuItems(user, setUser, resetFilterValues, true)}
            </ul>
          </div>
        </div>

        <div className={`container ${styles.wrapper}`}>
          <div className={styles.menu__hamburger}>
            <MenuUnfoldOutlined onClick={this.handleHamburgerClick} />
          </div>

          <Link className={styles.logo} to="/overview">
            <img src={logoWhite} alt="ShopInsights" />
          </Link>

          <div className={styles.menu}>
            <ul id="desktopMenu">{this.renderMenuItems(user)}</ul>
          </div>

          <div id="navProfile" className={styles.profile}>
            <UserOutlined className={this.getUserProfileIconClass(user)} />
            <span className={styles.profile__username}>{this.renderUserName(user)}</span>

            <div className={this.getUtilityMenuClass(user)}>
              <ul>{this.renderUtilityMenuItems(user, setUser, resetFilterValues, false)}</ul>
            </div>
          </div>
        </div>
      </nav>
    );
  };

  render() {
    return <AppContext.Consumer>{this.renderNavigation}</AppContext.Consumer>;
  }
}

NavigationRaw.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const Navigation = withRouter(withTranslation()(NavigationRaw));

export { Navigation as default, NavigationRaw };
