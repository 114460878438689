/* eslint-disable react/no-deprecated */
/* eslint-disable no-undef */
import React from 'react';
import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';

import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import './i18n/i18n';
import App from './App';

import './index.scss';

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

const rootElement = document.getElementById('root');

const component = (
  <Provider store={store}>
    <App />
  </Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(component, rootElement);
} else {
  render(component, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
