/* eslint-disable no-undef */
import React, { Component, Suspense, lazy } from 'react';
import { Router } from 'react-router-dom';
import _get from 'lodash/get';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import { isOneOfErrors, USER_STATES } from './utils/constants';
import api from './utils/api';
import { initFirebase } from './utils/firebase';
import Routes from './routes';
import Navigation from './components/common/Navigation';
import AppContext from './contexts/AppContext';
import PageLoader from './components/common/PageLoader';
import history from './history';
import TermsModal from './components/common/TermsModal';

const ErrorBoundary = lazy(() => import('./pages/ErrorBoundary' /* webpackChunkName: 'ErrorPage' */));

// Setup GA
const gaId =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_ID ? process.env.REACT_APP_GA_ID : 'UA-000000-01';
ReactGA.initialize(gaId, {
  debug: process.env.NODE_ENV === 'test',
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure',
  },
});

// Initialize google analytics page view tracking
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname }); // Update the user's current page
  ReactGA.pageview(pathname); // Record a pageview for the given page
});

// Set up firebase for load time tracking
initFirebase();

class App extends Component {
  state = {
    user: USER_STATES.NOT_AUTHENTICATED,
    authReturnUrl: null,
    brandsAndOutlets: null,
    iusAndOutlets: null,
    sbPayBrandsAndOutlets: null,
    filterValues: {
      brands: null, // String
      outlets: null, // Array of strings
      dateRange: null, // Tuple of moment dates
      transactionSearch: null,
      productType: null,
      unit: null,
    },
    productSubByBrand: null,
    showTermsModal: false,
  };

  componentDidMount() {
    this.trackFirstPageLoad();
    this.initUserEnv();
  }

  trackFirstPageLoad = () => {
    const path = _get(history, 'location.pathname', null);
    if (path) {
      ReactGA.pageview(path);
    }
  };

  initUserEnv = async (forceCheck = false) => {
    const { user } = this.state;
    const pageSessionStorage = window.sessionStorage;
    const termsRedirected = pageSessionStorage.getItem('termsRedirected');
    if (forceCheck || user === USER_STATES.NOT_AUTHENTICATED) {
      this.setState({ user: USER_STATES.AUTHENTICATING });
      const response = await api.getUserData();

      if (isOneOfErrors(response)) {
        this.setState({ user: USER_STATES.NOT_AUTHENTICATED });
      } else {
        const productSubByBrand = await api.getProductSubscriptions();

        const { brands: brandsAndOutlets, invoicingUnits: iusAndOutlets, ...userData } = response;

        if (brandsAndOutlets && userData) {
          // if terms have not yet been accepted & havent been redirected out
          if (!userData.termsAccepted && !termsRedirected) {
            pageSessionStorage.setItem('termsRedirected', true);
            await api.logout();
          }
          const showTermsModal = !userData.termsAccepted;
          this.setState({
            user: userData,
            brandsAndOutlets,
            iusAndOutlets,
            showTermsModal,
            productSubByBrand,
          });
        }
      }
    }
  };

  handleFilterValuesUpdate = (filterValues) => {
    const { ...values } = filterValues;

    this.setState((currentState) => {
      const updatedFilterValues = {
        ...currentState.filterValues,
        ...values,
      };

      return {
        ...currentState,
        filterValues: updatedFilterValues,
      };
    });
  };

  resetFilterValues = () => {
    this.setState({
      filterValues: {
        brands: null,
        outlets: null,
        dateRange: null,
      },
    });
  };

  setSbPayBrandsAndOutlets = (data) => {
    this.setState({ sbPayBrandsAndOutlets: data });
  };

  getFilterValues = (options = {}) => {
    const filterValueKeyValuePair = Object.entries(this.state.filterValues);

    // Remove null values
    return filterValueKeyValuePair.reduce((acc, keyValue) => {
      const [key, value] = keyValue;

      if (value) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});
  };

  closeTermsModal = () => {
    this.setState({
      showTermsModal: false,
    });
  };

  getMetaTagDescription = () => {
    if (process.env.REACT_APP_REGION === 'SG') {
      return 'Accept mobile payments. Customize your own Cashback & Loyalty programs. Reach out to millions of Singapore users with no upfront fee.';
    }
    return 'Customize your own Cashback & Loyalty Rewards with our card-linked technology. Currently live in Sydney.';
  };

  render() {
    const { showTermsModal } = this.state;

    return (
      <div className="app">
        <Helmet>
          <meta name="description" content={this.getMetaTagDescription()} />
        </Helmet>
        <AppContext.Provider
          value={{
            brandsAndOutlets: this.state.brandsAndOutlets,
            iusAndOutlets: this.state.iusAndOutlets,
            sbPayBrandsAndOutlets: this.state.sbPayBrandsAndOutlets,
            productSubByBrand: this.state.productSubByBrand,
            user: this.state.user,
            authReturnUrl: this.state.authReturnUrl,
            updateAuthReturnUrl: (url) => this.setState({ authReturnUrl: url }),
            setUser: (user) => this.setState({ user }),
            initUserEnv: this.initUserEnv,
            filterValues: this.state.filterValues,
            setSbPayBrandsAndOutlets: this.setSbPayBrandsAndOutlets,
            setFilterValues: this.handleFilterValuesUpdate,
            resetFilterValues: this.resetFilterValues,
            getFilterValues: this.getFilterValues,
          }}
        >
          <Router history={history}>
            <Navigation />

            {showTermsModal ? <TermsModal closeTermsModal={this.closeTermsModal} /> : null}

            <Suspense fallback={<PageLoader />}>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </Suspense>
          </Router>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
