import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PayLaterReport, RefundPayLaterOrderPartial } from './payLaterSlice.d';
/* eslint-disable no-param-reassign */

import {
  getPayLaterPosUuids,
  getPayLaterOrderList,
  refundPayLaterOrderFull,
  refundPayLaterOrderPartial,
  downloadPaylaterOrderReport,
} from '../../utils/apiV2';
import { ERROR_CODE, VALID_PAYLATER_ORDER_STATUSES } from '../../utils/constants';

import type {
  PayLaterState,
  PayLaterPosUUid,
  PayLaterSearchParams,
  PayLaterOrder,
  RefundOrderFullPartial,
  RefundStatus,
} from './payLaterSlice.d';

const initialState: PayLaterState = {
  fetchPosUuidsPending: false,
  posUuidsByIU: {},
  storeUuids: [],
  paylaterFilterReadyFirstTime: false,
  fetchPaylaterOrderPending: true,
  paylaterOrderRefundPending: false,
  activeFilterParams: {
    iuSelect: '',
    search: '',
    startDate: '',
    endDate: '',
    storesUuids: [],
    posUuids: [],
    pageIndex: 1,
    pageSize: 10,
    sortOrder: 'desc',
    isAllPosUuids: true,
    orderStatuses: VALID_PAYLATER_ORDER_STATUSES,
  },
  paylaterOrderRefundStatus: { status: '', requestId: '', type: '', code: '', message: '' },
  payLaterOrderList: [],
  totalCount: 0,
  totalOrderAmount: 0,
  totalOrders: 0,
  hasPaylaterOutlet: true,
  downloadPending: false,
};

export const fetchPosUuids = createAsyncThunk(
  'payLater/pos-uuids-fetch',
  async (
    {
      iuId,
      storesUuids,
    }: {
      iuId: string;
      storesUuids: string[];
    },
    { getState, rejectWithValue }
  ) => {
    const { payLater } = getState() as { payLater: PayLaterState };
    if (payLater.posUuidsByIU[iuId]) {
      return payLater.posUuidsByIU[iuId];
    }
    const posUuids = await getPayLaterPosUuids(storesUuids);
    if (posUuids === ERROR_CODE.CONNECTION) {
      return rejectWithValue(ERROR_CODE);
    }
    return posUuids as {
      [val: string]: PayLaterPosUUid[];
    }[];
  }
);

export const fetchPayLaterOrderList = createAsyncThunk(
  'payLater/order-list',
  async (searchParams: PayLaterSearchParams, { rejectWithValue }) => {
    const payLaterOrderList = await getPayLaterOrderList(searchParams);
    if (payLaterOrderList === ERROR_CODE.CONNECTION) {
      return rejectWithValue(ERROR_CODE);
    }

    return payLaterOrderList as {
      results: PayLaterOrder[];
      totalCount: 0;
      totalOrderAmount: 0;
      totalOrders: 0;
    };
  }
);

export const triggerDownloadPayLaterReport = createAsyncThunk(
  'payLater/report/download',
  async (params: PayLaterReport) => {
    const response = await downloadPaylaterOrderReport(params);
    if (response !== ERROR_CODE.CONNECTION) {
      return null;
    }
    return response as Blob;
  }
);

export const triggerRefundPayLaterOrderFull = createAsyncThunk(
  'paylater/orders/refund-full',
  async (payload: RefundOrderFullPartial) => {
    const refundStatus = await refundPayLaterOrderFull(payload);
    if (refundStatus === ERROR_CODE.CONNECTION) {
      return null;
    }
    return refundStatus as RefundStatus;
  }
);

export const triggerRefundPayLaterOrderPartial = createAsyncThunk(
  'paylater/orders/refund-partial',
  async (payload: RefundPayLaterOrderPartial) => {
    const refundStatus = await refundPayLaterOrderPartial(payload);
    if (refundStatus === ERROR_CODE.CONNECTION) {
      return null;
    }
    return refundStatus as RefundStatus;
  }
);

export const payLaterSlice = createSlice({
  name: 'payLater',
  initialState,
  reducers: {
    setActiveFilterParams: (state, action) => {
      state.activeFilterParams = action.payload;
    },
    setFetchPaylaterOrderPending: (state, action) => {
      state.fetchPaylaterOrderPending = action.payload;
    },
    setHasPaylaterOutlet: (state, action) => {
      state.hasPaylaterOutlet = action.payload;
    },
    setPaylaterFilterReadyFirstTime: (state, action) => {
      state.paylaterFilterReadyFirstTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchPosUuids loading when fetching
    builder.addCase(fetchPosUuids.pending, (state) => {
      state.fetchPosUuidsPending = true;
    });

    // fetchPosUuids on failure fetch
    builder.addCase(fetchPosUuids.rejected, (state, action) => {
      const {
        meta: {
          arg: { iuId },
        },
      } = action;
      if (!state.posUuidsByIU[iuId]) {
        state.posUuidsByIU = {
          ...state.posUuidsByIU,
          [iuId]: [],
        };
      }
      state.fetchPosUuidsPending = false;
      state.paylaterFilterReadyFirstTime = true;
    });

    // fetchPosUuids on success fetch
    builder.addCase(fetchPosUuids.fulfilled, (state, action) => {
      const {
        payload: posUuids,
        meta: {
          arg: { iuId },
        },
      } = action;

      state.fetchPosUuidsPending = false;
      if (!state.posUuidsByIU[iuId]) {
        state.posUuidsByIU = {
          ...state.posUuidsByIU,
          [iuId]: posUuids,
        };
      }
      state.paylaterFilterReadyFirstTime = true; // this is to make sure posUuids are loaded on first page load
    });

    // fetchPayLaterOrderList loading when fetching
    builder.addCase(fetchPayLaterOrderList.pending, (state) => {
      state.fetchPaylaterOrderPending = true;
    });

    // fetchPayLaterOrderList on failure fetch
    builder.addCase(fetchPayLaterOrderList.rejected, (state) => {
      state.fetchPaylaterOrderPending = false;
      state.totalCount = 0;
      state.totalOrderAmount = 0;
      state.totalOrders = 0;
      state.payLaterOrderList = [];
    });

    // fetchPayLaterOrderList on success fetch
    builder.addCase(fetchPayLaterOrderList.fulfilled, (state, action) => {
      const { payload: payLaterOrderList } = action;
      state.fetchPaylaterOrderPending = false;
      if (payLaterOrderList) {
        const { results, totalCount, totalOrderAmount, totalOrders } = payLaterOrderList;
        state.totalCount = totalCount;
        state.totalOrderAmount = totalOrderAmount;
        state.totalOrders = totalOrders;
        state.payLaterOrderList = results;
      }
    });

    // triggerRefundPayLaterOrderFull loading when fetching
    builder.addCase(triggerRefundPayLaterOrderFull.pending, (state) => {
      state.paylaterOrderRefundPending = true;
    });

    // triggerRefundPayLaterOrderFull on failure fetch
    builder.addCase(triggerRefundPayLaterOrderFull.rejected, (state) => {
      state.paylaterOrderRefundPending = false;
    });

    // triggerRefundPayLaterOrderFull on success fetch
    builder.addCase(triggerRefundPayLaterOrderFull.fulfilled, (state, action) => {
      const { payload: refundStatus } = action;
      state.paylaterOrderRefundPending = false;
      if (refundStatus) {
        state.paylaterOrderRefundStatus = refundStatus;
      }
    });
    // triggerRefundPayLaterOrderPartial loading when fetching
    builder.addCase(triggerRefundPayLaterOrderPartial.pending, (state) => {
      state.paylaterOrderRefundPending = true;
    });

    // triggerRefundPayLaterOrderPartial on failure fetch
    builder.addCase(triggerRefundPayLaterOrderPartial.rejected, (state) => {
      state.paylaterOrderRefundPending = false;
    });

    // triggerRefundPayLaterOrderPartial on success fetch
    builder.addCase(triggerRefundPayLaterOrderPartial.fulfilled, (state, action) => {
      const { payload: refundStatus } = action;
      state.paylaterOrderRefundPending = false;
      if (refundStatus) {
        state.paylaterOrderRefundStatus = refundStatus;
      }
    });

    builder.addCase(triggerDownloadPayLaterReport.pending, (state) => {
      state.downloadPending = true;
    });

    builder.addCase(triggerDownloadPayLaterReport.rejected, (state) => {
      state.downloadPending = false;
    });

    builder.addCase(triggerDownloadPayLaterReport.fulfilled, (state, action) => {
      state.downloadPending = false;
    });
  },
});

export default payLaterSlice.reducer;
