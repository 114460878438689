import axios, { AxiosError } from 'axios';
import { IVoucherDatum } from '../components/crm/customPushWizard.interface';
import { ReportData } from '../components/downloads/ReportTable.d';
import type {
  PayLaterReport,
  PayLaterSearchParams,
  PayPaylaterRefund,
  RefundOrderFullPartial,
  RefundPayLaterOrderPartial,
} from '../store/payLater/payLaterSlice.d';
import api from './api';
import apiV2 from './apiClient';
import { ISegmentationData } from './apiV2.interface';
import { ALL, EErrorCode, ERROR_CODE } from './constants';
import { v4 as uuidv4 } from 'uuid';

// Copied from CustomerInsights.js:302-310 which does not export this
export const getSegmentationData = async (
  currentBrandId: string | null,
  currentOutletIdOrAll: string | null
): Promise<ISegmentationData | null> => {
  if (!currentBrandId || !currentOutletIdOrAll) {
    return null;
  }
  const prefix = 'CiSegmentation';
  const filter =
    currentOutletIdOrAll === ALL
      ? { dimension: `${prefix}.brandId`, operator: 'equals', values: [currentBrandId] }
      : { dimension: `${prefix}.outletId`, operator: 'equals', values: [currentOutletIdOrAll] };
  const response = await api.queryCubeJs(
    {
      dimensions: [`${prefix}.nonActiveCustomer`, `${prefix}.oneOrderCustomer`, `${prefix}.repeatCustomer`],
      filters: [filter],
    },
    false
  );
  if (response === ERROR_CODE.CONNECTION) {
    return null;
  }
  const [segmentation] = response;
  const oneOrder = segmentation?.[`${prefix}.oneOrderCustomer`];
  const repeat = segmentation?.[`${prefix}.repeatCustomer`];
  const inactive = segmentation?.[`${prefix}.nonActiveCustomer`];
  if (!oneOrder || !repeat || !inactive) {
    return null;
  }
  return { oneOrder: parseInt(oneOrder, 10), repeat: parseInt(repeat, 10), inactive: parseInt(inactive, 10) };
};

export const getInventoryPost = async (
  brandIds: string[],
  outletIds: string[]
): Promise<IVoucherDatum[] | EErrorCode> => {
  const response = await api.client?.post('/merchant-portal/inventory', { brandIds, outletIds });
  return response?.data?.data ?? ERROR_CODE.CONNECTION;
};

export const downloadDarReport = async (downloadId: string, invoicingUnitName: string) => {
  const response = await api.getRequest(
    `merchant-portal/reports/dar-reports/${encodeURIComponent(downloadId)}/download`,
    {
      responseType: 'blob',
    },
    () => {},
    'data'
  );

  api.saveFile(response, `${invoicingUnitName}-${downloadId}.xlsx`);
};

export const downloadMicReport = async (downloadId: string, invoicingUnitName: string) => {
  const response = await api.getRequest(
    `merchant-portal/reports/monthly-invoice-credit/${encodeURIComponent(downloadId)}/download`,
    {
      responseType: 'blob',
    },
    () => {},
    'data'
  );

  api.saveFile(response, `${invoicingUnitName}-${downloadId}.pdf`);
};

export const downloadVarReport = async (downloadId: string, reportName: string) => {
  const response = await api.getRequest(
    `merchant-portal/reports/variable-activity-report/${encodeURIComponent(downloadId)}/download`,
    {
      responseType: 'blob',
    },
    () => {},
    'data'
  );

  api.saveFile(response, reportName);
};

export const downloadReport = async (report: ReportData) => {
  const { id: reportId, name, version, reportType, invoicingUnitId, downloadId } = report;

  const response = await api.getRequest(
    `merchant-portal/reports/${reportType}-reports/${encodeURIComponent(reportId)}/download`,
    {
      params: {
        invoicingUnitId,
        did: downloadId,
      },
      responseType: 'blob',
    },
    () => {},
    'data'
  );

  api.saveFile(response, `${name}-${version}.xlsx`);
};

export const downloadOthers = async (report: ReportData) => {
  const { id: reportId, name, version, reportType, invoicingUnitId, of } = report;

  const response = await api.getRequest(
    `merchant-portal/reports/${of}/${encodeURIComponent(reportId)}/${reportType}/download`,
    {
      params: {
        invoicingUnitId,
      },
      responseType: 'blob',
    },
    () => {},
    'data'
  );

  api.saveFile(response, `${name}-${version}-${reportType}.pdf`);
};

export const getTransactionReports = async (
  billingDates: string[],
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = []
) => {
  return api.postRequest(
    '/merchant-portal/reports/transaction-reports',
    {
      billingDates,
      pageNumber,
      limit,
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const getVoucherReports = async (
  billingDates: string[],
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = []
) => {
  return api.postRequest(
    '/public/reports/voucher-reports',
    {
      billingDates,
      pageNumber,
      limit,
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const getSettlementReport = async (
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = [],
  billingDates: string[]
) => {
  return api.postRequest(
    '/merchant-portal/reports/invoice-soa', // listing is actually invoice & soa of srs
    {
      billingDates,
      pageNumber,
      limit,
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const getMonthlyInvoiceCredits = async (
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = [],
  billingMonths: string[]
) => {
  return api.postRequest(
    '/merchant-portal/reports/monthly-invoice-credit',
    {
      billingMonths,
      pageNumber,
      limit,
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const getDarReports = async (
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = [],
  startDate: string,
  endDate: string
) => {
  return api.postRequest(
    '/merchant-portal/reports/dar-reports',
    {
      pageNumber,
      limit,
      invoicingUnitIds,
      startDate,
      endDate,
    },
    () => {},
    'data'
  );
};

export const getVarReports = async (pageNumber: number, rowsPerPage: number, invoicingUnitIds: string[] = []) => {
  return api.postRequest(
    '/merchant-portal/reports/variable-activity-report',
    {
      pageNumber,
      rowsPerPage,
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const generateVarReport = async (startDate: string, endDate: string, invoicingUnitId: string) => {
  return api.postRequest(
    '/merchant-portal/reports/variable-activity-report/create',
    {
      startDate,
      endDate,
      invoicingUnitId,
    },
    () => {},
    'data'
  );
};

export const getConsolidatedReport = async (
  pageNumber: number,
  limit: number,
  invoicingUnitIds: string[] = [],
  startDate: string,
  endDate: string
) => {
  return api.postRequest(
    '/merchant-portal/reports/consolidated-reports',
    {
      pageNumber,
      limit,
      invoicingUnitIds,
      startDate,
      endDate,
    },
    () => {},
    'data'
  );
};

export class InvoicingUnitDto {
  id: string;
  name: string;
  billingEmails: string[];
  salesforceId: string;
  createdAt: Date;
  updatedAt: Date;
}

export const getInvoicingUnitsForUser = async (): Promise<{
  data: {
    invoicingUnits: InvoicingUnitDto[];
  };
}> => {
  return api.getRequest('/merchant-portal/invoicing-units', {}, () => {}, 'data');
};

export const getVarFilterValues = async (invoicingUnitIds: string[] = []) => {
  return api.postRequest(
    '/merchant-portal/reports/variable-activity-report/filter-values',
    {
      invoicingUnitIds,
    },
    () => {},
    'data'
  );
};

export const getPayLaterPosUuids = async (storesUuids: string[]) => {
  return api.postRequest(
    '/merchant-portal/paylater/stores',
    {
      storesUuids,
      orderType: 'INSTORE',
    },
    () => {},
    'data'
  );
};

export const getPayLaterOrderList = async (searchParams: PayLaterSearchParams) => {
  return api.postRequest('/merchant-portal/paylater/orders', searchParams, () => {}, 'data');
};

const createHeadersWithIdempotentId = () => {
  return {
    'X-ShopBack-Idempotent-Id': uuidv4(),
  };
};

export const refundPayLaterOrderFull = async (payload: RefundOrderFullPartial) => {
  const config = {
    headers: createHeadersWithIdempotentId(),
  };

  try {
    const response = await apiV2.client.post('/merchant-portal/paylater/orders/refund-full', payload, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 412) {
      return error.response.data;
    }
    return ERROR_CODE.CONNECTION;
  }
};

export const refundPayLaterOrderPartial = async (payload: RefundPayLaterOrderPartial) => {
  const config = {
    headers: createHeadersWithIdempotentId(),
  };

  try {
    const response = await apiV2.client.post('/merchant-portal/paylater/orders/refund-partial', payload, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 412) {
      return error.response.data;
    }
    return ERROR_CODE.CONNECTION;
  }
};

export const refundPayLaterOrderFullPartial = async (payload: PayPaylaterRefund) => {
  const config = {
    headers: createHeadersWithIdempotentId(),
  };

  try {
    const response = await apiV2.client.post('/merchant-portal/paylater/oms/refund', payload, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 412) {
      return error.response.data;
    }
    return ERROR_CODE.CONNECTION;
  }
};

export const refundPayOrderFullPartial = async (payload: PayPaylaterRefund) => {
  const config = {
    headers: createHeadersWithIdempotentId(),
  };

  try {
    const response = await apiV2.client.post('/merchant-portal/paylater/pay/oms/refund', payload, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 412) {
      return error.response.data;
    }
    return ERROR_CODE.CONNECTION;
  }
};

export const downloadPaylaterOrderReport = async (payload: PayLaterReport) => {
  const response = await api.postRequest('/merchant-portal/paylater/report/generate', payload, () => {}, 'data');
  if (response === ERROR_CODE.CONNECTION) {
    return null;
  }

  if (payload.storesUuids[0] && response.reportFiles) {
    const data = await api.postRequestConfig(
      '/merchant-portal/paylater/report/download',
      {
        storeToken: payload.storesUuids[0],
        reportFile: response.reportFiles[0],
      },
      {
        responseType: 'blob',
      },
      () => {},
      'data'
    );
    if (data === ERROR_CODE.CONNECTION) {
      return null;
    }
    return api.saveFile(data, 'report.zip');
  }
  return response;
};
