import fetch from 'cross-fetch';
import 'url-search-params-polyfill';

class HttpTransport {
  constructor({ authorization, apiUrl, headers = {}, credentials }) {
    this.authorization = authorization;
    this.apiUrl = apiUrl;
    this.headers = headers;
    this.credentials = credentials ? { credentials } : {};
  }

  request(method, params) {
    const searchParams = new URLSearchParams(
      params &&
        Object.keys(params)
          .map((k) => ({ [k]: typeof params[k] === 'object' ? JSON.stringify(params[k]) : params[k] }))
          .reduce((a, b) => ({ ...a, ...b }), {})
    );

    const runRequest = async () => {
      const response = await fetch(
        `${this.apiUrl}/${method}${searchParams.toString().length ? `?${searchParams}` : ''}`,
        {
          headers: { Authorization: this.authorization, 'Content-Type': 'application/json', ...this.headers },
          ...this.credentials,
        }
      );

      // Temporary work around as Cubejs does not return http error codes
      // Sentry error: https://sentry.io/organizations/shopback/issues/1708816841/?project=5253515&query=is%3Aunresolved
      // Source code: https://github.com/cube-js/cube.js/blob/32402e6aaaded72dcd1f45313f691d31e09b20b5/packages/cubejs-client-core/src/index.js#L135
      // CubeJS Bug ticket: https://github.com/cube-js/cube.js/issues/833
      // JIRA: https://shopadmin.atlassian.net/browse/OCS-6792
      if (response.status !== 200) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      }

      return response;
    };

    return {
      async subscribe(callback) {
        const result = await runRequest();
        return callback(result, () => this.subscribe(callback));
      },
    };
  }
}

export default HttpTransport;
