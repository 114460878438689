import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './en.json';
import thTranslations from './th.json';

const activeLng = process.env.REACT_APP_REGION !== 'TH' ? 'en' : 'th';
i18n.use(initReactI18next).init({
  resources: {
    en: enTranslations,
    th: thTranslations,
  },
  fallbackLng: 'en',
  lng: activeLng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
