/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';
import { USER_STATES } from '../utils/constants';
import { IAppContext } from './AppContext.interface';

const noop = () => {};

// TODO refactor initialization here
const defaultAppContextValue: IAppContext = {
  authReturnUrl: null,
  brandsAndOutlets: null,
  iusAndOutlets: null,
  sbPayBrandsAndOutlets: null,
  productSubByBrand: null,
  filterValues: {
    brands: null,
    outlets: null,
    dateRange: null,
    inventory: undefined,
    transactionSearch: null,
    unit: undefined,
    opportunity: undefined,
  },
  setFilterValues: noop,
  setSbPayBrandsAndOutlets: noop,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFilterValues: (options: any) => {},
  setUser: noop,
  updateAuthReturnUrl: noop,
  user: USER_STATES.NOT_AUTHENTICATED,
  initUserEnv: noop,
  resetFilterValues: noop,
};
const AppContext = createContext(defaultAppContextValue);

export const useAppContext = () => useContext(AppContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAppContext = (ChildComponent: any) => (props: any) => {
  const appContext = useContext(AppContext);
  return <ChildComponent {...props} appContext={appContext} />;
};

export { AppContext as default, withAppContext };
