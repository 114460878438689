/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React, { lazy, useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import AppContext from './contexts/AppContext';
import { PAGE_TYPES, APP_SG_URL, APP_AU_URL, APP_TH_URL, APP_MY_URL, LEAD_INBOUND_URL } from './utils/constants';

const Overview = lazy(() => import('./pages/Overview' /* webpackChunkName: 'OverviewPage' */));

const CustomerInsights = lazy(() => import('./pages/CustomerInsights' /* webpackChunkName: 'CustomerInsightsPage' */));

const ReportsInvoices = lazy(() =>
  import('./pages/finance/reportsInvoices/ReportsInvoices' /* webpackChunkName: 'ReportsInvoicesPage' */)
);

const ReportsTransactions = lazy(() =>
  import('./pages/finance/transactions/Transactions' /* webpackChunkName: 'ReportsTransactionsPage' */)
);

// TODO in same folder
const CrmPortal = lazy(() => import('./pages/CrmPortal' /* webpackChunkName: 'CrmPortalPage' */));
const CustomPush = lazy(() => import('./pages/CustomPush' /* webpackChunkName: 'CustomPushPage' */));

const OutletCodes = lazy(() => import('./pages/OutletCodes' /* webpackChunkName: 'OutletCodesPage' */));

const Login = lazy(() => import('./pages/Login' /* webpackChunkName: 'LoginPage' */));
const Register = lazy(() => import('./pages/Register' /* webpackChunkName: 'RegisterPage' */));
const Home = lazy(() => import('./pages/Home' /* webpackChunkName: 'HomePage' */));

const UpdatePassword = lazy(() => import('./pages/UpdatePassword' /* webpackChunkName: 'OverviewPage' */));

const Admin = lazy(() => import('./pages/admin/Admin' /* webpackChunkName: 'AdminPage' */));
const CreateMerchantGroup = lazy(() =>
  import('./pages/admin/CreateMerchantGroup' /* webpackChunkName: 'CreateMerchantGroupPage' */)
);
const CreateMerchantAccount = lazy(() =>
  import('./pages/admin/CreateMerchantAccount' /* webpackChunkName: 'CreateMerchantAccountPage' */)
);
const CreateMerchantUser = lazy(() =>
  import('./pages/admin/CreateMerchantUser' /* webpackChunkName: 'CreateMerchantUserPage' */)
);
const CreateAcl = lazy(() => import('./pages/admin/CreateAcl' /* webpackChunkName: 'CreateAclPage' */));

const PayLater = lazy(() => import('./pages/payLater/PayLater' /* webpackChunkName: 'PayLaterPage' */));

const Error404 = lazy(() => import('./pages/Error404' /* webpackChunkName: 'Error404Page' */));

// Docs
// https://reacttraining.com/react-router/web/example/route-config

class OutletAccessError extends Error {
  constructor(message) {
    super(message);
    this.name = 'noOutlets';
  }
}

const routesConfig = [
  {
    path: '/signin',
    component: Login,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.login',
  },
  {
    path: '/register',
    component: Register,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.login',
  },
  {
    path: '/customer-insights',
    component: CustomerInsights,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.segmentation',
  },
  {
    path: '/finance/invoice',
    component: ReportsInvoices,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.finance.dropdown.invoices',
  },
  {
    path: '/transactions',
    component: ReportsTransactions,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.finance.dropdown.transactions',
  },
  {
    path: '/broadcast/create-a-notification',
    component: CustomPush,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.crm.customPush',
  },
  {
    path: '/broadcast',
    component: CrmPortal,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.crm.index',
  },
  {
    path: '/outlet-codes',
    component: OutletCodes,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.outletCodes',
  },
  {
    path: '/overview',
    component: Overview,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.overview',
    type: PAGE_TYPES.AUTH_HOME,
  },
  {
    path: '/reset-password/*',
    component: UpdatePassword,
    exact: false,
    breadcrumbs: false,
    translationKey: '',
  },
  {
    path: '/finance/paylater',
    component: PayLater,
    exact: true,
    breadcrumbs: true,
    translationKey: 'menu.payLaterOrders',
  },

  // Admin Routes
  {
    path: '/admin/create-merchant-group',
    component: CreateMerchantGroup,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.overview',
  },
  {
    path: '/admin/create-merchant-account',
    component: CreateMerchantAccount,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.overview',
  },
  {
    path: '/admin/create-acl',
    component: CreateAcl,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.overview',
  },
  {
    path: '/admin/create-merchant-user',
    component: CreateMerchantUser,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.overview',
  },
  {
    path: '/admin',
    component: Admin,
    exact: true,
    breadcrumbs: false,
    translationKey: 'menu.overview',
  },

  {
    path: '/',
    component: Home,
    exact: true,
    breadcrumbs: false,
    translationKey: '',
    type: PAGE_TYPES.HOME,
  },
];

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => {
      if (route.component) {
        if (route.path === '/register' || route.path === '/') {
          if (window.location.host === APP_SG_URL) {
            window.location.href = `${LEAD_INBOUND_URL}/sg`;
            return null;
          }
          if (window.location.host === APP_AU_URL) {
            window.location.href = `${LEAD_INBOUND_URL}/au`;
            return null;
          }
          if (window.location.host === APP_TH_URL) {
            window.location.href = `${LEAD_INBOUND_URL}/th/paylater`;
            return null;
          }
          if (window.location.host === APP_MY_URL) {
            window.location.href = `${LEAD_INBOUND_URL}/my`;
            return null;
          }
        }
        // pass the sub-routes down to keep nesting
        return <route.component {...props} routes={route.routes} />;
      }
      return null;
    }}
  />
);

const Routes = () => {
  const appContext = useContext(AppContext);
  const { brandsAndOutlets, iusAndOutlets } = appContext;

  useEffect(() => {
    /**
     * to trigger this check on every routes.
     */
    if (brandsAndOutlets !== null && _isEmpty(brandsAndOutlets) && _isEmpty(iusAndOutlets)) {
      throw new OutletAccessError("You don't have access to any outlets");
    }
  }, [brandsAndOutlets]);

  return (
    <Switch>
      {routesConfig.map((route) => (
        <RouteWithSubRoutes key={route.path} {...route} />
      ))}
      <Route component={Error404} />
    </Switch>
  );
};

export { Routes as default, RouteWithSubRoutes, routesConfig };
