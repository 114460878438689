import React, { FC, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { withTranslation } from 'react-i18next';
import styles from './TermsModal.module.scss';
import { IProps } from './TermsModal.d';
import api from '../../utils/api';

const TermsModalRaw: FC<IProps> = ({ t, closeTermsModal }) => {
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeTick = (e: CheckboxChangeEvent) => {
    setAgreement(e.target.checked);
  };

  const handleContinue = async (): Promise<void> => {
    setLoading(true);
    const response = await api.acceptTerms();

    const { status } = response;
    if (status === 200) {
      closeTermsModal();
    } else {
      setAgreement(false);
    }
  };

  const footer = [
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={handleContinue}
      disabled={!agreement}
      data-testid="termsModalButton"
    >
      Continue
    </Button>,
  ];

  return (
    <Modal
      wrapClassName={styles['terms-modal-wrapper']}
      maskStyle={{ zIndex: 2000 }}
      title={t('termsModal.header')}
      closable={false}
      visible={true}
      footer={footer}
      centered
    >
      <Checkbox onChange={onChangeTick} className={styles['terms-checkbox']}>
        {`${t('termsModal.checkbox')} `}
        <br />
        <a href={t('termsModal.link')} target="_blank" rel="noopener noreferrer">
          {t('termsModal.linkText')}
        </a>
      </Checkbox>
    </Modal>
  );
};

const TermsModal = withTranslation()(TermsModalRaw);

export { TermsModal as default, TermsModalRaw };
