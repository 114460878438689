import firebase from 'firebase/app';
import 'firebase/performance';

// NOTE: These are public keys and need to be exposed
// Permissions has been set for these keys on firebase

const devConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_DEV,
  authDomain: 'shopback-dev-68e96.firebaseapp.com',
  projectId: 'shopback-dev-68e96',
  storageBucket: 'shopback-dev-68e96.appspot.com',
  messagingSenderId: '85167701666',
  appId: '1:85167701666:web:a965dfd0dcce237c82e1fb',
};

const prodConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_PROD,
  authDomain: 'moolah-shopback-2014.firebaseapp.com',
  databaseURL: 'https://moolah-shopback-2014.firebaseio.com',
  projectId: 'moolah-shopback-2014',
  storageBucket: 'moolah-shopback-2014.appspot.com',
  messagingSenderId: '926511958493',
  appId: '1:926511958493:web:af0bcf5dbb032a6d3b0e34',
};

let firebaseInstance = null;

const initFirebase = () => {
  if (process.env.REACT_APP_FIREBASE_ENV) {
    // Initialize firebase
    let firebaseConfig = {};

    if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
      firebaseConfig = prodConfig;
    } else if (
      process.env.REACT_APP_FIREBASE_ENV === 'development' ||
      process.env.REACT_APP_FIREBASE_ENV === 'staging'
    ) {
      firebaseConfig = devConfig;
    } else {
      return;
    }
    firebase.initializeApp(firebaseConfig);

    // Initialize Performance Monitoring and get a reference to the service
    firebaseInstance = firebase.performance();
  }
};

export { initFirebase, firebaseInstance };
