import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { ERROR_CODE } from './constants';

const DOMAIN_HEADER = 'x-shopback-domain';
const DEFAULT_MAX_RETRY_DURATION = 30000; // 30 seconds
const DEFAULT_RETRY_INTERVAL = 100; // 0.1 second
const MAX_BACKOFF_INTERVAL = 1000; // 1 second
const MULTIPLIER = 2; // Exponential backoff multiplier

enum JitterStrategy {
  None = 'None',
  Full = 'Full',
  Equal = 'Equal',
}

export class APIClient {
  client: AxiosInstance;
  defaultHeaders: object;
  maxRetryDuration: number;
  defaultRetryInterval: number;

  constructor(maxRetryDuration = DEFAULT_MAX_RETRY_DURATION, defaultRetryInterval = DEFAULT_RETRY_INTERVAL) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      [DOMAIN_HEADER]: process.env.REACT_APP_DOMAIN,
    };
    this.client = axios.create({
      headers: this.defaultHeaders,
      withCredentials: true,
      baseURL: `${process.env.REACT_APP_API_URL}/sbgo-merchant-platform-information-service`,
    });
    this.maxRetryDuration = maxRetryDuration;
    this.defaultRetryInterval = defaultRetryInterval;

    this.client.interceptors.response.use(
      (response) => response,
      (error) => this.retryRequest(error)
    );
  }

  private async sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private calculateBackoffInterval(retryCount: number, jitterStrategy: JitterStrategy = JitterStrategy.Full): number {
    const exponentialInterval = Math.min(this.defaultRetryInterval * MULTIPLIER ** retryCount, MAX_BACKOFF_INTERVAL);

    switch (jitterStrategy) {
      case JitterStrategy.Equal:
        return exponentialInterval / 2 + Math.random() * (exponentialInterval / 2);
      case JitterStrategy.Full:
        return Math.random() * exponentialInterval;
      case JitterStrategy.None:
      default:
        return exponentialInterval;
    }
  }

  private async retryRequest(error: AxiosError): Promise<AxiosResponse | AxiosError> {
    const status = error.response?.status;
    if (status === 429 || status === 503) {
      const config = error.config as AxiosRequestConfig & { _retryCount?: number; _startTime?: number };
      config._retryCount = config._retryCount || 0;
      config._startTime = config._startTime || Date.now();

      const retryAfterHeader = error.response?.headers ? error.response.headers['Retry-After'] : undefined;
      const retryInterval = retryAfterHeader
        ? parseFloat(retryAfterHeader) * 1000
        : this.calculateBackoffInterval(config._retryCount);

      const elapsedTime = Date.now() - config._startTime;
      if (elapsedTime + retryInterval <= this.maxRetryDuration) {
        config._retryCount += 1;
        await this.sleep(retryInterval);

        return this.client.request(config);
      } else {
        return Promise.reject(
          new Error(
            `Request failed after ${config._retryCount} retries and ${Math.floor(elapsedTime / 1000)} seconds due to ${
              status === 429 ? 'Too Many Requests' : 'Service Unavailable'
            }.`
          )
        );
      }
    }
    return Promise.reject(error);
  }

  async postRequest(path: string, options = {}, errorCallback = (error: AxiosError) => {}, dataPath = 'data.data') {
    try {
      const response = await this.client.post(path, options);
      const data = _get(response, dataPath);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return errorCallback(error);
      }
      return ERROR_CODE.CONNECTION;
    }
  }
}

const client = new APIClient();

export default client;
