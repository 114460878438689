import React from 'react';
import { Spin } from 'antd';

import styles from './PageLoader.module.scss';

const Loader = () => (
  <>
    <div className={styles.loader}>
      <div className={styles.loader__bar} />
    </div>

    <div className={styles.spinner}>
      <Spin size="large" />
    </div>
  </>
);

export default Loader;
