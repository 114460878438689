/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import cubejs from '@cubejs-client/core';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import ReactGA from 'react-ga';
import config from '../config/config.json';
import { DEFAULT_PORTAL_CONFIG, ERROR_CODE, FORMATTED_DEFAULT_DATE_RANGE, PASSWORD_RESET_STATES } from './constants';
import CustomHttpTransport from './cubejs/CustomHttpTransport';
import { firebaseInstance } from './firebase';
import { APIClient as BaseAPIClient } from './apiClient';

const DOMAIN_HEADER = 'x-shopback-domain';

const SIGNIN_PATH = '/signin';
const SIGNUP_PATH = '/register';
const RESET_PW_PATH = '/reset-password';

const TIMEZONE = _get(config, `${process.env.REACT_APP_REGION}.timezone`, 'Asia/Singapore');

function handleRequestError(error, errorCallback) {
  if (_get(error, 'response.status') === 401) {
    if (
      [SIGNIN_PATH, SIGNUP_PATH].indexOf(window.location.pathname) === -1 &&
      window.location.pathname.indexOf(RESET_PW_PATH) === -1
    ) {
      window.location.href = SIGNIN_PATH;
    }

    return ERROR_CODE.AUTH;
  }
  Sentry.captureException(error);
  errorCallback(error);
  return ERROR_CODE.CONNECTION;
}

class APIClient extends BaseAPIClient {
  constructor() {
    super();
    this.initClients();
  }

  initClients() {
    const cubejsConfig = {
      authorization: process.env.REACT_APP_CUBEJS_API_SECRET,
      apiUrl: `${process.env.REACT_APP_API_URL}/sbgo-merchant-platform-information-service/merchant-portal`,
      headers: this.defaultHeaders,
      credentials: 'include', // For including cooking in cross-domain request
    };

    this.cubejsClient = cubejs(process.env.REACT_APP_CUBEJS_API_SECRET, {
      ...cubejsConfig,
      // Custom transport for httpOnly cookie
      transport: new CustomHttpTransport(cubejsConfig),
    });
  }

  async postRequest(path, options = {}, errorCallback = () => {}, dataPath = 'data.data') {
    try {
      const response = await this.client.post(path, options);
      const data = _get(response, dataPath);
      return data;
    } catch (error) {
      return handleRequestError(error, errorCallback);
    }
  }

  async postRequestConfig(path, options = {}, axiosConfig = {}, errorCallback = () => {}, dataPath = 'data.data') {
    try {
      const response = await this.client.post(path, options, axiosConfig);
      const data = _get(response, dataPath);
      return data;
    } catch (error) {
      return handleRequestError(error, errorCallback);
    }
  }

  async getRequest(path, options = {}, errorCallback = () => {}, dataPath = 'data.data') {
    try {
      const response = await this.client.get(path, options);
      const data = _get(response, dataPath);
      return data;
    } catch (error) {
      return handleRequestError(error, errorCallback);
    }
  }

  /**
   * Query auth server
   * @param {Object} formData Login form data
   */
  async login(formData) {
    try {
      const pageSessionStorage = window.sessionStorage;
      const termsRedirected = pageSessionStorage.getItem('termsRedirected');

      if (!termsRedirected) {
        pageSessionStorage.setItem('termsRedirected', true);
      }

      return await this.client.post(
        '/auth/login',
        {
          ...formData,
          countryCode: process.env.REACT_APP_REGION,
          country: 'SG'
        },
        {
          headers: {
            ...this.defaultHeaders,
            'x-shopback-country': 'SG',
            'x-forwarded-host': 'https://www.shopback.sg'
          },
        }
      );
    } catch (error) {
      if (error.response) {
        return error.response;
      }

      Sentry.captureException(error);
      return error;
    }
  }

  /**
   * Make call to API to logout
   */
  async logout() {
    try {
      return await this.client.post('/auth/logout');
    } catch (error) {
      return error;
    }
  }

  async requestPasswordReset(email) {
    const { SUCCESS, FAILED } = PASSWORD_RESET_STATES;

    try {
      const { status } = await this.client.post(
        '/auth/password-reset',
        {
          email,
          countryCode: process.env.REACT_APP_REGION,
        },
        {
          headers: {
            ...this.defaultHeaders,
          },
        }
      );
      return status === 204 ? SUCCESS : FAILED;
    } catch (error) {
      Sentry.captureException(error);
      return FAILED;
    }
  }

  async validateResetPasswordToken(token) {
    if (_isEmpty(token)) {
      return false;
    }

    try {
      const { data } = await this.client.post('/auth/password-reset/validate', { token });
      return _get(data, 'data.status', false);
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

  async updatePassword({ token, newPassword, confirmPassword }) {
    if (_isEmpty(token) || _isEmpty(newPassword) || _isEmpty(confirmPassword)) {
      return false;
    }

    try {
      const { data } = await this.client.put(
        '/auth/password-update',
        {
          token,
          newPassword,
          confirmPassword,
          countryCode: process.env.REACT_APP_REGION,
        },
        {
          headers: {
            ...this.defaultHeaders,
          },
        }
      );

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return {};
    }
  }

  /**
   * API call for registration
   */
  async register(values) {
    try {
      return await this.client.post('/users/request-registration', values);
    } catch (error) {
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  /**
   * Call to get list of outlets that user has permissions
   */
  async getUserData() {
    return this.getRequest('/auth/me');
  }

  /**
   * Call to update user terms_accepted
   */
  async acceptTerms() {
    return this.client.post('/merchant-user/accept-terms');
  }

  /**
   * @todo check why eslint throwing error.
   */
  // eslint-disable-next-line class-methods-use-this
  async getCustomMessages(outletIds = [], page = 1) {
    if (_isEmpty(outletIds)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }
    const outletIdsStr = outletIds.join(',');
    /**
     * first page only need 5 records
     */
    return this.getRequest(
      '/crm/messages',
      { params: { outletIds: outletIdsStr, page, size: page === 1 ? 5 : 8 } },
      null,
      'data'
    );
  }

  async getOutletCodes(outletIds, brandIds, page = 1, size = 10) {
    if (!Array.isArray(outletIds) || _isEmpty(outletIds) || !Array.isArray(brandIds) || _isEmpty(brandIds))
      return ERROR_CODE.INVALID_PARAMETER;
    const outletIdsStr = outletIds.join(',');
    const brandIdsStr = brandIds.join(',');
    return this.getRequest(
      '/terminal-account/search',
      {
        params: { outletIds: outletIdsStr, brandIds: brandIdsStr, page, size },
      },
      undefined,
      'data'
    );
  }

  async getOutletCodesCsv(outletIds, brandIds) {
    if (!Array.isArray(outletIds) || _isEmpty(outletIds) || !Array.isArray(brandIds) || _isEmpty(brandIds))
      return ERROR_CODE.INVALID_PARAMETER;
    const outletIdsStr = outletIds.join(',');
    const brandIdsStr = brandIds.join(',');
    return this.getRequest(
      '/terminal-account/download',
      {
        params: { outletIds: outletIdsStr, brandIds: brandIdsStr },
      },
      () => {},
      'data'
    );
  }

  async getCashbackRates(outletIds, brandIds) {
    if (!Array.isArray(outletIds) || _isEmpty(outletIds) || !Array.isArray(brandIds) || _isEmpty(brandIds))
      return ERROR_CODE.INVALID_PARAMETER;
    const outletIdsStr = outletIds.join(',');
    const brandIdsStr = brandIds.join(',');
    return this.getRequest('/merchant-portal/cashback-rates', {
      params: { outletIds: outletIdsStr, brandIds: brandIdsStr },
    });
  }

  async getInventories(brandId, outletIds) {
    if (_isEmpty(brandId) || !Array.isArray(outletIds) || _isEmpty(outletIds)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }

    const outletIdsStr = outletIds.join(',');
    return this.getRequest('/merchant-portal/inventory', { params: { brandIds: brandId, outletIds: outletIdsStr } });
  }

  async getUserInventory(args) {
    const { inventoryIds, outletId, brandId, next, limit = 10, startDate, endDate } = args;

    if (_isEmpty(inventoryIds) || _isEmpty(brandId) || _isEmpty(startDate) || _isEmpty(endDate)) {
      Sentry.captureMessage(
        JSON.stringify({
          message: 'Invalid params for getUserInventory',
          params: args,
        })
      );
      return ERROR_CODE.INVALID_PARAMETER;
    }
    const inventoryIdsString = inventoryIds.join(',');

    return this.getRequest('/user-inventory', {
      params: {
        inventory_ids: inventoryIdsString,
        brand_id: brandId,
        ...(outletId ? { outlet_id: outletId } : {}),
        ...(next ? { next } : {}),
        limit,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  async getUserInventoryCsv({ listingCodes, outletId, brandId, dateRange }) {
    if (_isEmpty(listingCodes)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }

    return this.postRequest(
      '/merchant-portal/user-inventory/download',
      {
        inventoryCodes: listingCodes,
        outletId,
        brandId,
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      },
      () => {},
      'data'
    );
  }

  async getLoyalties(brandId, outletIds) {
    if (_isEmpty(brandId) || !Array.isArray(outletIds) || _isEmpty(outletIds)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }
    const outletIdsStr = outletIds.join(',');
    return this.getRequest('/merchant-portal/loyalties', { params: { brandIds: brandId, outletIds: outletIdsStr } });
  }

  async getLoyaltyCampaignCsv({ campaignId, outletIds }) {
    if (_isEmpty(campaignId) || _isEmpty(outletIds)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }

    return this.getRequest(
      '/loyalty-campaign/download',
      {
        params: {
          campaign_id: campaignId,
          outlet_ids: outletIds,
        },
      },
      () => {},
      'data'
    );
  }

  async getPartnerCashbacks(brandId, outletIds) {
    if (_isEmpty(brandId) || !Array.isArray(outletIds) || _isEmpty(outletIds)) {
      return ERROR_CODE.INVALID_PARAMETER;
    }
    const outletIdsStr = outletIds.join(',');
    return this.getRequest('/merchant-portal/partner-cashbacks', {
      params: { brandIds: brandId, outletIds: outletIdsStr },
    });
  }

  async queryCubeJs(query, returnPivot = true, skipTracking = false) {
    // See OCS-10859
    // values: Array? is excluded if undefined
    // When something goes wrong during querying (undefined filters)
    // should fall through this check and call GA
    if (query?.filters?.some(({ values }) => values && _isEmpty(values))) return [];

    const trackDefault =
      !skipTracking &&
      (!query.timeDimensions ||
        (_get(query.timeDimensions, '0.dateRange.0') === FORMATTED_DEFAULT_DATE_RANGE[0] &&
          _get(query.timeDimensions, '0.dateRange.1') === FORMATTED_DEFAULT_DATE_RANGE[1]));
    const queryStartTime = new Date();
    const generalTracer = firebaseInstance?.trace('CubeJS-api-call');
    const defaultPageTracer = firebaseInstance?.trace('CubeJS-default-api-call');
    let cubeSpecificTracer;

    // TODO: Include Firebase api calls in unit test once this feature
    // is merged with https://shopadmin.atlassian.net/browse/OCS-10790
    if (query.measures || query.dimensions) {
      cubeSpecificTracer = query.measures
        ? firebaseInstance?.trace(query.measures.join(','))
        : firebaseInstance?.trace(query.dimensions.join(','));
    }

    try {
      // inject query with country timezone
      _set(query, 'timezone', TIMEZONE);

      // Firebase custom traces
      generalTracer && !skipTracking && generalTracer.start();
      cubeSpecificTracer && cubeSpecificTracer.start();
      defaultPageTracer && trackDefault && defaultPageTracer.start();

      const response = await this.cubejsClient.load(query);
      const rawData = response.rawData();
      if (_isEmpty(rawData)) {
        return [];
      }

      return returnPivot ? response.chartPivot() : rawData;
    } catch (error) {
      if (error.status === 401) {
        if (
          [SIGNIN_PATH, SIGNUP_PATH].indexOf(window.location.pathname) === -1 &&
          window.location.pathname.indexOf(RESET_PW_PATH) === -1
        ) {
          window.location.href = '/signin';
        }
      } else {
        Sentry.captureException(error);
      }
      return ERROR_CODE.CONNECTION;
    } finally {
      // Stop all load time tracking
      generalTracer && !skipTracking && generalTracer.stop();
      cubeSpecificTracer && cubeSpecificTracer.stop();
      defaultPageTracer && trackDefault && defaultPageTracer.stop();

      ReactGA.timing({
        category: 'CubeQuery',
        variable: 'load',
        value: new Date() - queryStartTime,
        label: JSON.stringify(query),
      });
    }
  }

  /**
   * ADMIN API CALLS
   */
  async createMerchantGroup(formData) {
    try {
      const { data } = await this.client.post('/groups', {
        name: formData.name,
        outletIds: formData.outlets,
        invoicingUnitIds: formData.invoicingUnits,
      });

      return data;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getMerchantGroups() {
    return this.getRequest('/groups');
  }

  async createMerchantAccount(formData) {
    const { name, groupId, outletIds, invoicingUnitIds } = formData;

    try {
      const { data } = await this.client.post('/accounts', {
        name,
        groupId,
        outletIds,
        invoicingUnitIds,
      });
      return data;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getMerchantAccounts(groupIds) {
    return this.getRequest('/accounts', { params: { groupIds } });
  }

  async createAcl(formData) {
    const { merchantAccountIds, permissions = {}, filters = {} } = formData;

    try {
      const { data } = await this.client.post('/acls', {
        merchantAccountIds,
        permissions,
        filters,
      });
      return data;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getAcls() {
    return this.getRequest('/acls');
  }

  async createMerchantUser(formData) {
    const { firstName, lastName, email, password } = formData;

    try {
      const { data } = await this.client.post('/users', {
        firstName,
        lastName,
        email,
        password,
      });
      return data;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getAdminUsers() {
    return this.getRequest('/users');
  }

  /**
   * CRM API CALLS
   * TODO: Refactor API to separate
   * api calls per module
   */
  async getPendingSendCustomPushes(outlets) {
    const outletIdsStr = outlets.join();

    try {
      const response = await this.getRequest('/crm/messages/pending-send', { params: { outletIds: outletIdsStr } });
      return response;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async createCustomPush(customPush) {
    try {
      const response = await this.client.post('/crm/messages', customPush);

      return response;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getTransactions(params) {
    try {
      const response = await this.client.get('/public/transaction', {
        params,
      });

      return response;
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getUmbTransactions(params) {
    try {
      const response = await this.client.post('/merchant-portal/transactions', params);

      return response;
    } catch (err) {
      if (_get(err, 'response.status') === 400) {
        return _get(err, 'response.data');
      }
      Sentry.captureException(err);
      return ERROR_CODE.CONNECTION;
    }
  }

  async downloadUmbTransactions(params) {
    try {
      const response = await this.client.post('/merchant-portal/transactions/download', params);
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      return this.saveFile(blob, `transactions-${Date.now()}.csv`);
    } catch (err) {
      if (_get(err, 'response.status') === 400) {
        return _get(err, 'response.data');
      }
      Sentry.captureException(err);
      return ERROR_CODE.CONNECTION;
    }
  }

  async downloadTransactionReport(params) {
    try {
      const response = await this.client.post('/merchant-portal/transactions/download', {
        params,
        responseType: 'blob',
      });

      return this.saveFile(response.data, `transactions-${Date.now()}.csv`);
    } catch (error) {
      if (_get(error, 'response.status') === 400) {
        return _get(error, 'response.data');
      }
      Sentry.captureException(error);
      return ERROR_CODE.CONNECTION;
    }
  }

  async getProductSubscriptions() {
    // @todo add error monitoring here https://shopadmin.atlassian.net/browse/MS-400
    return this.getRequest('/merchant-portal/products/brand', {}, () => {}, 'data.data');
  }

  async getPortalConfig() {
    try {
      const response = await this.client.get('/merchant-portal/get-portal-config');
      return response.data;
    } catch (err) {
      return DEFAULT_PORTAL_CONFIG;
    }
  }

  saveFile = (data, name) => {
    saveAs(data, name);
  };
}

const client = new APIClient();

export default client;
